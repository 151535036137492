import React from "react";

import Layout from "../../../components/layout";
import { Helmet } from 'react-helmet';
import data from '../../../data/warranty';

export default function () {

    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${data.title} | Clean Air Filter`}</title>
        </Helmet>
        <article>
            <section>
                <h1><span className="tail"><span className="head">{data.content.section1.h1.head}</span> {data.content.section1.h1.tail}</span></h1>
                <article className="w-60">
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p1 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p2 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p3 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p4 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p5 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p6 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p7 }}></p>
                    <ul>
                        {data.content.section1.ul.map(item => <li><p dangerouslySetInnerHTML={{ __html: item }}></p></li>)}
                    </ul>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p8 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p9 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p10 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p11 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p12 }}></p>
                    <p dangerouslySetInnerHTML={{ __html: data.content.section1.p13 }}></p>
                    {/* <p dangerouslySetInnerHTML={{ __html: data.content.section1.p14 }}></p> */}
                </article>
            </section>
        </article>
    </Layout>;
}
